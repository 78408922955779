import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';
import { DateRangePickerService } from '@shared/components/datepicker-range-preset/datepicker-preset-header/date-range-picker.service';
import { DatepickerPresetHeaderComponent } from '@shared/components/datepicker-range-preset/datepicker-preset-header/datepicker-preset-header.component';
import {
  DateRange,
  ExtractDateTypeFromSelection,
  MatDatepickerInputEvent,
  MatDateRangePicker
} from '@angular/material/datepicker';
import moment from 'moment-timezone';

@Component({
  selector: 'app-datepicker-range-preset',
  templateUrl: './datepicker-range-preset.component.html',
  styleUrls: ['./datepicker-range-preset.component.scss'],
  providers: [DateRangePickerService]
})
export class DatepickerRangePresetComponent {

  protected readonly DatepickerPresetHeaderComponent = DatepickerPresetHeaderComponent;
  @Input() customPresets = [
    'today',
    'last_14_days',
    'last_30_days',
    'previous_6_months',
    'previous_12_months'
  ];
  @Input() range: FormGroup =  new FormGroup({
    start: new FormControl(moment().toDate()),
    end: new FormControl(moment().toDate()),
  });
  @Input() classNames: string;
  @Output() dateChangeEvent = new EventEmitter<any>();
  constructor(public  dateRangePickerService: DateRangePickerService) {
  this.dateRangePickerService.customPresets = this.customPresets;
  }
  applySelectedDate(picker: MatDateRangePicker<Date>): void {
    this.range.setValue({
      start: (picker.datepickerInput as any).value.start,
      end: (picker.datepickerInput as any).value.end,
    });

    this.dateChangeEvent.emit({
      start: (picker.datepickerInput as any).value.start,
      end: (picker.datepickerInput as any).value.end,
    })
    picker.close();
  }

}
