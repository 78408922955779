<!-- <mat-card class="mat-elevation-z3" style="border-style: none;"> -->
<mat-card class="p-0 m-0 mat-elevation-z7 content " style="border-bottom-right-radius: 0;border-top-right-radius: 0" >
  <div class="p-2 " style="font-size:13px;font-family: Montserrat, sans-serif; font-weight: 600">
    {{ selectedDateRange  }}
  </div>
  <mat-divider></mat-divider>
    <mat-nav-list class="p-0 m-0" style="overflow-y: auto;font-family: Montserrat, sans-serif; font-weight: 600">
      <mat-list-item class="p-2"
        *ngFor="let item of customPresets"
        (click)="selectRange(item)"
        [ngClass]="{ 'preset': item === selectedRange }"
      >
        {{ 'website.datepicker_range_preset.'+item | translate }}
      </mat-list-item>

    </mat-nav-list>
</mat-card>
